// custom colors
$primary: #04081E; // main accent color
$background: #030610;
$text: #c4c4c4; // text color
$text-shadow: #737373; // text accent color
$accent: rgb(255, 235, 167); // main accent color

$lineheight: 1.45; // document line height

// custom fonts
$userfont: Stolzl, sans-serif; // main fonts
$systemfont: -apple-system, BlinkMacSystemFont, Arial, sans-serif; // system fonts

// custom scrollbar
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, .5);
  width: 5px;
}

::-webkit-scrollbar-track {
  background: 0 0;
}

.container {
  overflow: hidden;
}

* {
  user-select: none;
}