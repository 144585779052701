@import '../../shared/scss/config';

.con-container {
  margin-top: 11em;

  h2#text {
    position: relative;
    font-size: 2.4em;
    text-align: center;
    max-width: 320px;
    margin: 0 auto;

    &:before {
      content: '';
      position: absolute;
      width: 15px;
      height: 15px;
      border: 2px solid rgba(238, 238, 238, 0.58);
      left: -20px;
      top: 0;
      border-bottom: none;
      border-right: none;
    }

    &:after {
      content: '';
      position: absolute;
      width: 15px;
      height: 15px;
      border: 2px solid rgba(238, 238, 238, 0.58);
      right: -20px;
      bottom: 0;
      border-top: none;
      border-left: none;
    }
  }

  .contact-content {
    display: flex;
    gap: 12em;
    justify-content: center;
    align-items: center;
    margin: 6em 1em;

    .links {
      display: flex;
      flex-direction: column;
      gap: 1.5em;

      .link__item {
        position: relative;
        display: flex;
        gap: 1.2em;
        justify-content: start;
        align-items: center;

        .icon {
          opacity: 0.8;
          color: #dad8c4;

          .css-i4bv87-MuiSvgIcon-root {
            font-size: 2.4em;
          }
        }

        .information {
          span {
            color: rgba($text, 0.98);
            font-size: 1.2em;
          }

          p {
            color: $accent;
          }
        }
      }
    }

    .contact-form {
      display: flex;
      flex-direction: column;
      padding: 0.5em;
      background: #091025;
      min-height: 400px;
      max-width: 460px;
      width: 100%;
      justify-content: center;
      align-items: center;

      form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0.7em;
        padding: 1em;

        textarea {
          min-height: 120px;
          padding: 1em 1.2em;
          font-family: $userfont;
        }
      }
    }
  }
}

#formText {
  text-align: center;
  font-size: 3em;
  margin-top: 8em;
  margin-bottom: 6em;
}

input, textarea {
  width: 300px;
  padding: 1em 1.2em;
  font-family: $userfont;
  background: transparent;
  color: #eee !important;
  border: 1px solid rgba(238, 238, 238, 0.42);
  border-radius: 6px;
}

::placeholder {
  color: #eee;
}

@media screen and (max-width: 1080px) {
  .con-container {
    margin-top: 6em!important;
  }
  .contact-content {
    flex-direction: column-reverse;
    gap: 4em!important;
    margin-bottom: 3em!important;
    margin-top: 3em!important;
  }

  h2#text {
    width: 300px;
    font-size: 1.8em !important;

    &:before {
      left: 1em !important;
    }

    &:after {
      right: 1em !important;
    }
  }

  .information {
    span {
      font-size: 0.8em!important;
    }
  }
}
