// CUSTOM CURSOR

#cursor, #aura {
  position: absolute;
  border-radius: 100%;
  transition: .5s cubic-bezier(.75, -1.27, .3, 2.33) transform, .2s cubic-bezier(.75, -1.27, .3, 2.33) opacity;
  user-select: none;
  pointer-events: none;
  z-index: 10000;
}

#cursor {
  width: 8px;
  height: 8px;
  background-color: rgba(#fff, 0.5);
  &.active {
    opacity: 0;
    transform: scale(0);
  }
}

#aura {
  width: 46px;
  height: 46px;
  border: 1px solid rgba(#fff, .2);
  background-color: rgba(#fff, .027);
  transform: translate(5px, 5px);
  &.active {
    border: 1.2px solid rgba(#fff, 0.33);
    transform: scale(1.2);
  }
}

//* {
//  cursor: none;
//}

#cursor.hidden,
#aura.hidden {
  transform: scale(0.001);
}