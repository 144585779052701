@import '../../shared/scss/config';

header {
  height: 88vh;
}

.header-overlay {
  width: 100%;
  height: 82.2vh;
  overflow: hidden;
  position: absolute;
}

.header-overlay video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.header-gradient-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 0;

  .gradient-fade {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  .fade-bottom {
    background: linear-gradient(0deg, #000, rgba(0, 0, 0, .8) 30%, rgba(0, 0, 0, .7) 45%, transparent 80%, transparent);
    bottom: 0;
    top: auto;
  }

  .fade-left {
    background: linear-gradient(270deg, #000, rgba(0, 0, 0, .5) 5%, rgba(0, 0, 0, .2) 20%, transparent 45%);
    right: 0;
    left: auto;
  }

  .fade-right {
    background: linear-gradient(90deg, #000, rgba(0, 0, 0, .5) 5%, rgba(0, 0, 0, .2) 20%, transparent 45%);
    left: 0;
    right: auto;
  }
}

.overlay-line {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 5em;
  width: 100%;
  height: 2px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.21);
  z-index: 999;

  .circle {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 2px solid rgba(229, 229, 229, 0.27);
    transition: 0.4s all;

    .arrow {
      position: relative;
      border: solid #ffffff;
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 3px;
      margin-top: 2em;

      transition: 0.4s all;
    }

    .down {
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
    }

    &:hover {
      transform: scale(1.1);

      .arrow {
        padding: 6px;
      }
    }
  }
}

.header-logo-container {
  width: 100%;
  position: absolute;
  bottom: 8em;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;

  .underlogo-desc {
    position: relative;
    padding: 20px;
    margin: 0px 1em;
    .bracket {
      width: 15px;
      height: 15px;
      display: block;
      position: absolute;
      border: 1px solid #eee;
    }
    .bracket:first-child {
      left: 0;
      top: 0;
      border-bottom: none;
      border-right: none;
    }
    .bracket:nth-child(2) {
      left: 0;
      bottom: 0;
      border-top: none;
      border-right: none;
    }
    .bracket:nth-child(4) {
      right: 0;
      top: 0;
      border-bottom: none;
      border-left: none;
    }
    .bracket:last-child {
      right: 0;
      bottom: 0;
      border-top: none;
      border-left: none;
    }

    .content {
      font-size: 1.2em;
    }
  }

  .logo {
    opacity: 0.9;

    img {
      width: 100%;
      max-width: 300px;
    }
  }
}

@media screen and (max-width: 540px) {
  .circle {
    width: 92px!important;
    height: 92px!important;

    .arrow {
      margin-top: 1em!important;
    }
  }
}