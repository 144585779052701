@import '../../shared/scss/config';

nav {
  background: radial-gradient(50% 100% at 50% 100%,hsla(0,0%,100%,.1) 0,hsla(0,0%,100%,0) 100%);
  background-color: rgba(4, 8, 30, 1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  position: fixed;
  top: 0;
  z-index: 1000;
  overflow: hidden;

  .response-menu {
    z-index: 999;
    position: fixed;
    min-height: 100%;
    margin-top: 3.5em;
    top: 0;
    bottom: 0;
    left: 0;
    display: none;
    flex-direction: column;
    background-color: #040817;
    transform: translateX(-1000px);
    opacity: 0;
    width: 100%;
    transition: 0.6s transform, 0.3s opacity;

    .nav-link {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 20px;
      color: $text;
      font-weight: 600;
      text-decoration: none;
      text-transform: uppercase;
      font-size: 16px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.15);

      &:hover {
        background: rgba(255, 255, 255, 0.05);
      }

      &:before {
        border: 1px solid rgba(255, 255, 255, 0.25);
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: $accent;
        display: block;
        content: '';
      }
    }

    &[data-opened="true"] {
      transition: 0.6s transform, 0.3s opacity;
      transform: translateX(0px);
      opacity: 1;
    }
  }

  .nav-center {
    text-align: center;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2em;

    .nav-link {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 5.5em;
      padding: 0 1em;

      color: #e5e5e5;
      text-decoration: none;
      font-size: 1.1em;

      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }

    a > img {
      height: 4em;
    }
  }

  .nav-buttons {
    gap: 1em;

    display: flex;
    justify-content: center;
    align-items: center;

    .menu-btn, .btn {
      display: none;
    }

    .menu-btn {
      z-index: 999999;
    }
  }
}

.btn {
  text-decoration: none !important;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 44px;
  border: solid 1px #fff;
  background: 0 0;
  color: #fff;
  transition: background .2s ease, color .2s ease;
  font-size: 0.9em;
  font-weight: 400;
  font-family: 'Archive', sans-serif;

  a {
    color: #fff;
    text-decoration: none;
  }

  &:hover {
    background: white;
    color: #1b1b1b;

    a {
      color: #1b1b1b;
    }
  }
}

.icon-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  color: white;
  background: transparent;
  outline: none;
  border: none;

  transition: 0.2s background-color;

  &:hover {
    background-color: white;
    color: #1b1b1b;
  }
}

@media screen and (max-width: 978px) {

  .response-menu {
    display: flex!important;
  }

  nav {
    justify-content: space-between;
    height: 3.5em;

    &[data-opened="true"] {
      justify-content: end;
    }

    .nav-center {
      margin-left: 3em;
      .nav-link {
        display: none;
      }

      a > img {
        height: 3em;
      }
    }

    .nav-buttons {
      margin-right: 2.2em;

      .menu-btn, .btn {
        display: flex;
      }
    }
  }
}